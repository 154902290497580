export enum SOCKET_ACTIONS {
  USER_STATUS = 'USER_STATUS',
  MESSAGE = 'MESSAGE'
}

export enum StatusType {
  COLLECTION = 'COLLECTION',
  SINGLE = 'SINGLE'
}

export enum MESSAGE_TYPE {
  TEXT = 'TEXT',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO'
}

export interface Chat {
  roomId: string;
  userId: number;
  createdDate: string | Date;
}

export interface MessagePagination {
  content: Message[];
  page: {
    size: number;
    number: number;
    totalElements: number;
    totalPages: 3;
  };
}

export interface SocketMessage extends Message, UserStatus {
  type: SOCKET_ACTIONS;
}
export interface Message {
  chatRoomId: string;
  senderId: number;
  receiverId?: number;
  text: string;
  messageType: MESSAGE_TYPE;
  sendTime: string;
  editTime: string;
}

export interface UserStatus {
  statusType: StatusType;
  online: boolean;
  userIds: number[];
}

export interface ChatObservable {
  userId: number;
  isOnline: boolean;
  username: string;
  name: string;
  photo: string;
  roomId: string;
  messages: Message[];
  created_at: string;
  role: any[];
  seenMessage?: boolean;
}
