import { Injectable } from '@angular/core';
import { MainService } from 'src/app/shared/Services/main.service';
import { environment } from 'src/environments/environment';
import { ChatService } from './chat.service';
import { Message, SOCKET_ACTIONS, SocketMessage, StatusType } from '../models/chat';
import { Client } from '@stomp/stompjs';
import { Notification, NotificationType } from '../../profile/models/notification';
import { PostService } from '../../home/services/post.service';
import { BehaviorSubject } from 'rxjs';
const CHAT_BASE_URL = environment.CHAT_WEBSOCKET_URL;
const NOTIF_BASE_URL = environment.NOTIF_WEBSOCKET_URL;
@Injectable({
   providedIn: 'root'
})
export class WebsocketService {
   chatStompClient: any;
   notifStompClient: any;

   private socketConnectionsValue = { chat: false, notification: false };
   private socketSource: BehaviorSubject<{ chat: boolean; notification: boolean }> = new BehaviorSubject<{
      chat: boolean;
      notification: boolean;
   }>(this.socketConnectionsValue);
   socketConnections = this.socketSource.asObservable();

   constructor(
      private mainService: MainService,
      private chatService: ChatService,
      private postService: PostService
   ) {
      this.startSocket();
   }

   startSocket() {
      this.mainService.userData.subscribe({
         next: userData => {
            if (userData) {
               const token = localStorage.getItem('token');
               this.chatSocketConnect(userData, token as string);
               this.notifSocketConnect(userData, token as string);
            }
         }
      });
   }

   notifSocketConnect(userData: any, token: string) {
      if (this.notifStompClient?.active) {
         return;
      }

      const connectHeaders = {
         Authorization: token as string,
         userId: userData.id
      };

      this.notifStompClient = new Client({
         brokerURL: NOTIF_BASE_URL + '?Authorization=' + token,
         connectHeaders: connectHeaders,
         onConnect: () => {
            console.log('notif socket connected');
            this.socketConnectionsValue.notification = true;
            this.socketSource.next(this.socketConnectionsValue);
            this.notifStompClient.subscribe(
               `/user/${userData.id}/queue/messages`,
               (message: any) => {
                  this.onNotificationReceived(message);
               },
               { userId: userData.id }
            );
         },
         debug: msg => {},
         onStompError: frame => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
         },
         onWebSocketClose: event => {
            console.error('WebSocket closed unexpectedly: ', event);
         },
         onWebSocketError: error => {
            console.error('WebSocket error: ', error);
         }
      });
      this.notifStompClient.activate();
   }

   async onNotificationReceived(payload: any) {
      const socketMessage: Notification = JSON.parse(payload.body);
      switch (socketMessage.type) {
         case NotificationType.GAIN_CB:
            this.postService.setCBGained(socketMessage.content);
            break;
         default:
            break;
      }
   }

   chatSocketConnect(userData: any, token: string) {
      if (this.chatStompClient?.active) {
         return;
      }

      const connectHeaders = {
         Authorization: token as string,
         userId: userData.id
      };

      this.chatStompClient = new Client({
         brokerURL: CHAT_BASE_URL + '?Authorization=' + token,
         connectHeaders: connectHeaders,
         onConnect: () => {
            console.log('chat socket connected');
            this.socketConnectionsValue.chat = true;
            this.socketSource.next(this.socketConnectionsValue);
            this.chatStompClient.subscribe(
               `/user/${userData.id}/queue/messages`,
               (message: any) => {
                  this.onMessageReceived(message);
               },
               { userId: userData.id }
            );
         },
         debug: msg => {},
         onStompError: frame => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
         },
         onWebSocketClose: event => {
            this.chatStompClient.activate();
            console.error('WebSocket closed unexpectedly: ', event);
         },
         onWebSocketError: error => {
            console.error('WebSocket error: ', error);
         }
      });
      this.chatStompClient.activate();
   }

   async onMessageReceived(payload: any) {
      const socketMessage: SocketMessage = JSON.parse(payload.body);
      switch (socketMessage.type) {
         case SOCKET_ACTIONS.MESSAGE:
            this.chatService.addUnreadMessageCount();
            this.chatService.addNewMessageToChat(socketMessage, false);
            break;
         case SOCKET_ACTIONS.USER_STATUS:
            if (socketMessage.statusType === StatusType.COLLECTION) {
               this.setAllUsersStatus(socketMessage.userIds);
               return;
            }
            if (socketMessage.statusType === StatusType.SINGLE) {
               this.setUserStatus(socketMessage.userIds[0], socketMessage.online);
               return;
            }
            break;
      }
   }

   setAllUsersStatus(userList: number[]) {
      this.chatService.setOnlineUsers(userList);
   }

   setUserStatus(userId: number, online: boolean) {
      this.chatService.setUserStatus(userId, online);
   }

   stopSocket() {
      if (this.chatStompClient) {
         this.chatStompClient.deactivate();
      }

      if (this.notifStompClient) {
         this.notifStompClient.deactivate();
      }
   }

   sendMessage(message: Message) {
      if (message && this.chatStompClient) {
         const chatMessage = {
            senderId: message.senderId,
            chatRoomId: message.chatRoomId,
            text: message.text
         };
         this.chatStompClient.publish('/app/chat', {}, JSON.stringify(chatMessage));
         this.chatStompClient.publish({ destination: '/app/chat', body: JSON.stringify(chatMessage) });
         this.chatService.addNewMessageToChat(message);
      }
   }
}
